
import * as Api from '../util/api/Api.js';
import uri from '../util/tag_uri.js';


export const url = process.env.API_URL;

const agent =  Api.createAgent({
    uri: `${url}/api-v1`,
    getAuthToken: () => {
        if (localStorage && localStorage.getItem('auth')) {
            const auth = JSON.parse(localStorage.getItem('auth'));
            return auth.token;
        } else {
            return null;
        }
    },
});

agent.use(request => {
    if (window.site) {
        request.query({ site_id: window.site.id });
    }
    
    return request;
});

export default agent;
