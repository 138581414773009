
import moment from 'moment';
import * as Random from '../../util/random.js';

import React from 'react';
import PropTypes from 'prop-types';
import * as ReactRedux from 'react-redux';
import { Helmet } from 'react-helmet';
import { Link } from 'react-router-dom';
import inject from '../../util/di/inject.js';

import apiAgent from '../../services/CpgAgent.js';
import api, { parseEventListResponse } from '../../services/CpgApi.js';
import Event from '../../models/Event.js';
import Collection from '../../util/models/Collection.js';

import EventsSliderList from './EventsSliderList.js';
import EventsSlider from './EventsSlider.js';
import EventFeatured from './EventFeatured.js';

import * as FavoriteService from '../../services/model/FavoriteService.js';
import SitesService from '../../services/SitesService.js';
import SimpleCitiesFilterDrenthe from './SimpleCitiesFilterDrenthe.js';


@inject({
    responsive: 'cpg.responsive',
})
@ReactRedux.connect(
    state => ({
        // events: state.getIn(['app', 'events']),
        eventGroups: state.getIn(['app', 'eventgroups']),
        myList: state.app.authUser.eventFavorites,
        auth: state.getIn(['session', 'auth']),
    }),
    dispatch => ({
        dispatch,
    }),
    (stateProps, dispatchProps, otherProps) => ({
        ...stateProps,
        ...dispatchProps,
        ...otherProps,
        fetchMyList: () => {
            if (stateProps.auth) {
                return dispatchProps.dispatch(api.eventFavorites.list());
            } else {
                return Promise.resolve();
            }
        },
        toggleFavorite: event => {
            return FavoriteService.toggleFavorite({
                auth: stateProps.auth,
                dispatch: dispatchProps.dispatch,
                myList: stateProps.myList,
                event,
            });
        },
    }),
)
export class Events extends React.PureComponent {
    static propTypes = {
        eventGroups: PropTypes.instanceOf(Collection).isRequired,
        myList: PropTypes.instanceOf(Collection).isRequired,
        auth: PropTypes.object, // Note: may be null (if no session)
    };
    
    static defaultProps = {
        auth: null,
    };
    
    state = {
        active: false,
    };
    
    seed = 1;
    
    constructor(props) {
        super(props);
        
        // Generate a random seed to use for randomizing events
        this.seed = Random.generateRandomInt(1, 1000);
    }
    
    load(prevProps, prevState) {
        const { eventGroups, myList } = this.props;
        
        if (myList.meta.status === 'invalid') {
            this.props.fetchMyList();
        }
        
        if (eventGroups.meta.status === 'invalid') {
            this.props.dispatch(api.eventGroups.list({ home: true }));
        }
    }
    
    refresh() {
        this.props.dispatch(api.eventGroups.list({ home: true }));
        this.props.fetchMyList();
    }
    
    componentDidMount() {
        //this.load();
        
        //XXX This must be refresh for now, because we cannot rely on the status. The `app.eventgroups` part
        // of the store is reused by CategoryOverview to store a different kind of event group list.
        this.refresh();
    }
    componentDidUpdate(prevProps, prevState) { this.load(prevProps, prevState); }
    
    render() {
        const { myList, eventGroups: eventGroupsRaw, auth } = this.props;
        const site = new SitesService().getSite(window.location);
        
        const helmet =
            <Helmet>
                <body className={`page-events ${this.state.active ? 'slider-open' : ''}`}/>
                <title>Events</title>
                <meta name="description" content={`Events in ${site.title}`}/>
            </Helmet>;
        
        const isLoading = myList.meta.status === 'pending' || eventGroupsRaw.meta.status === 'pending';
        const isReady = !eventGroupsRaw.isEmpty();
        
        if (isLoading || !isReady) {
            return (
                <div className="content-wrapper">
                    {helmet}
                    
                    <div>
                        <header className="container-fluid jumbotron jumbotron-homepage loading">
                            <div className="row vertical-align-sm">
                                <div className="jumbotron-homepage-slider-wrapper">
                                    {/* Image slider */}
                                </div>
                                <div className="col-xs-12 col-sm-8 col-lg-6 col-xl-5 jumbotron-homepage-content-wrapper vertical-align-xs">
                                    <div className="jumbotron-homepage-content">
                                        {/* Content */}
                                    </div>
                                </div>
                            </div>
                        </header>
                    </div>
                </div>
            );
        }
        
        // Disabled until we get better artwork
        // const promo = (site.name === 'groningen') ? <PromoGroningen /> : null;
        const promo = null;
        const simpleCitiesFilter = (site.name === 'drenthe') ? <SimpleCitiesFilterDrenthe /> : null;
        
        // XXX the 'my-list' special event group has been replaced with its own section in the store
        let eventGroups = eventGroupsRaw;
        if (auth) {
            eventGroups = eventGroupsRaw
                .setIn(['my-list', 'events'], myList)
                .setIn(['my-list', 'disabled'], myList.size === 0);
        }
        
        // The 'featured' group is treated specially
        const featuredEventGroup = eventGroups.get('featured');
        
        const eventGroupsRest = eventGroups.remove('featured');
        
        let featured, featuredSlider;
        if (featuredEventGroup && featuredEventGroup.get('events').size !== 0) {
            const featuredEvents = featuredEventGroup.get('events')
                .sortBy(event => moment(event.start_date).format('YYYY-MM-DD'));
            
            const featuredEvent = featuredEvents.sortBy(Random.createRandomGenerator(this.seed)).first();
            
            featured = (
                <div style={{ position: 'relative' }}>
                    {promo}
                    
                    <EventFeatured
                        auth={auth}
                        event={featuredEvent}
                        update={() => this.update()}
                    />
                </div>
            );
            
            // Note: hide this slider if there are less than 5 items
            featuredSlider = featuredEvents.size < 5 ? null : (
                <EventsSlider
                    auth={auth}
                    update={() => this.update()}
                    title={
                        featuredEventGroup.get('link') && featuredEventGroup.get('link') !== ''
                            ?
                                <Link to={`/category/${featuredEventGroup.get('link')}`}>
                                    {featuredEventGroup.getIcon()}
                                    {featuredEventGroup.name}
                                </Link>
                            :
                                <span className="nolink">{featuredEventGroup.name}</span>
                    }
                    events={featuredEvents.filter(event => event !== featuredEvent)}
                />
            );
        }
        
        return (
            <div className="content-wrapper">
                {helmet}
                
                {featured}

                {simpleCitiesFilter}
                
                <EventsSliderList
                    onActivate={isActive => { this.setState({ active: isActive }); }}
                    enableScroll={true}
                >
                    {featuredSlider}
                    
                    {eventGroupsRest.mapToElements((eventGroup, eventGroupId) =>
                        <EventsSlider
                            key={eventGroupId}
                            disabled={
                                eventGroup.get('disabled')
                                    ?
                                        <div className="slider-inner-disabled vertical-align">
                                            <div className="well well-white">
                                                {!auth
                                                    ? <span className="h3">
                                                        Please <Link to={`/register/`}>register</Link> or <Link to={`/login/`}>login</Link> to get recommended events
                                                    </span>
                                                    : <span className="h3">
                                                        Your list is still empty.<br />
                                                        Click on "Add to My list" to add your first event
                                                    </span>
                                                }
                                            </div>
                                        </div>
                                    :
                                        null
                            }
                            auth={auth}
                            update={() => this.refresh()}
                            title={
                                eventGroup.get('link') && eventGroup.get('link') !== ''
                                    ?
                                        <Link to={`/category/${eventGroup.get('link')}`}>
                                            {eventGroup.getIcon()}
                                            {eventGroup.get('name')}
                                            
                                            <span className="icon icon-arrow-right"
                                                aria-hidden="true" aria-label={`More ${eventGroup.get('name')}`}
                                            />
                                        </Link>
                                    :
                                        <span className="nolink">{eventGroup.get('name')}</span>
                            }
                            anchorName={eventGroup.get('name')}
                            events={eventGroup.events}
                            myList={this.props.myList}
                            toggleFavorite={this.props.toggleFavorite}
                            loadMore={diff => {
                                const eventsCount = eventGroup.events.size;
                                
                                // If we have already loaded all events (so the actual count is equal to the
                                // "total"), then ignore
                                if (eventsCount === eventGroup.total) {
                                    return;
                                }
                                
                                const params = {
                                    offset: eventsCount,
                                    limit: 5,
                                };
                                
                                apiAgent.get(`/event-groups/${encodeURIComponent(eventGroupId)}`, params)
                                    .then(response => {
                                        const eventsResponse = response.body.events; // Plain array
                                        
                                        const events = parseEventListResponse(eventsResponse);
                                        
                                        // Ignore if no additional events found
                                        if (events.size === 0) {
                                            return;
                                        }
                                        
                                        const eventsUpdated = eventGroup.events.merge(events);
                                        const eventGroupUpdated = eventGroup.set('events', eventsUpdated);
                                        
                                        this.props.dispatch({
                                            type: 'api.load',
                                            requestId: '',
                                            path: ['app', 'eventgroups', eventGroupId],
                                            status: 'ready',
                                            value: eventGroupUpdated,
                                        });
                                    });
                            }}
                        />
                    )}
                </EventsSliderList>
            </div>
        );
    }
}
